import { Button, Grid } from "@mui/material";
import { FC } from "react";
import { BaseLayout } from "./BaseLayout";
import Logo from "../assets/images/logo.png";
import Phones from "../assets/images/phones.png";

export const DashboardPage: FC = () => {
  return (
    <BaseLayout>
      <Grid
        container
        justifyContent={"center"}
        className="p-10 bg-gradient-to-b from-white via-white to-opacityBlue"
      >
        <Grid item md={8} container>
          <Grid item md={6} sm={12} xs={12}>
            <div className="flex justify-center items-center h-full">
              <div className="text-center w-full">
                <div className="flex items-center w-full justify-center">
                  <img src={Logo} alt="Logo" width={67} />
                  <h1 className="pl-2 font-dosis-bold text-darkBlue text-2xl tracking-widest">
                    OptiListen
                  </h1>
                </div>
                <p className="font-sfpro-regular mb-10 tracking-widest">
                  Unlock the listener within
                </p>

                <a href="https://apps.apple.com/app/optilisten/id1593948410"><Button
                  variant="contained"
                  color="info"
                  className="bg-gradient-to-r from-[#78C693] to-[#34A0A4]"
                  sx={{
                    borderRadius: 100,
                    fontSize: 18,
                    color: "white",
                    textTransform: "none",
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  Try the Free App
                  </Button></a>
                <p className="font-sfpro-medium mt-2 tracking-widest">
                </p>
              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12}>
            <img
              src={Phones}
              alt="Phones"
              className="m-[-110px] mt-24 mx-auto"
            />
          </Grid>
        </Grid>
      </Grid>
      <div className="w-full h-4  bg-opacityBlue curve-radius shadow-2xl" />
      <Grid container justifyContent={"center"} className="p-10 my-24">
        <Grid item md={8} container>
          <Grid xs={12} item className="bg-white p-6 rounded-xl">
            <p className="text-midNight font-sfpro-regular text-xl ">

OptiListen is an easy-to-use iPhone app created for folks 
who would like to listen a little more, and a little better. 
            </p>
            <p className="text-midNight font-sfpro-regular text-xl mt-4">
It does this by recognizing speech and tracking "talking" 
versus "not talking." (We're taking the leap that "not talking" 
means "listening" rather than "multitasking" because you are 
awesome and focused and present and we believe in you. :-) )
            </p>
            <p className="text-midNight font-sfpro-regular text-xl mt-4">
<b>To use OptiListen,</b> start by <a href="https://apps.apple.com/app/optilisten/id1593948410"><u>installing the free app</u></a> on your iPhone.
            </p>
            <p className="text-midNight font-sfpro-regular text-xl mt-4">
The next time you're using your computer for a call or meeting, 
be sure to put your headphones on. That way the only speech that 
the app will register is yours. 
            </p>
            <p className="text-midNight font-sfpro-regular text-xl mt-4">
Run the app, and select <b>Add a new speaking session.</b> If you'd like, 
you can also adjust your goal -- for example, you might want to try 
keeping your talk time below 35% in one meeting and below 50% in another.
            </p>
            <p className="text-midNight font-sfpro-regular text-xl mt-4">
Track your progress. And get ready to start building your listening 
skills, one audio or video call at a time.
            </p>
            <p className="text-midNight font-sfpro-regular text-xl mt-4">
<i><b>Note:</b> All data is kept locally on your phone.</i>
</p>
          </Grid>
        </Grid>
      </Grid>
    </BaseLayout>
  );
};
